// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.errorText{
    font-family: Manulife JH Sans Optimized;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    margin-left: 10px;
    color: #A00E18;
}
.errorContainer{
    margin-top: 0 !important;
    display: flex;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/style/InputError.css"],"names":[],"mappings":";AACA;IACI,uCAAuC;IACvC,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,iBAAiB;IACjB,cAAc;AAClB;AACA;IACI,wBAAwB;IACxB,aAAa;IACb,mBAAmB;AACvB","sourcesContent":["\r\n.errorText{\r\n    font-family: Manulife JH Sans Optimized;\r\n    font-style: normal;\r\n    font-weight: 600;\r\n    font-size: 13px;\r\n    line-height: 16px;\r\n    margin-left: 10px;\r\n    color: #A00E18;\r\n}\r\n.errorContainer{\r\n    margin-top: 0 !important;\r\n    display: flex;\r\n    align-items: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
