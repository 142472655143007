import { Grid } from '@mui/material';
import React, { useContext, useEffect } from 'react'
import "../style/LinkExpiredComponentStyle.css"
import { MyContext } from '../Context/MyContext';


const LinkExpiredComponent = () => {

    const context = useContext(MyContext);

    useEffect(()=> {
        context.setSessionTimeoutEnable(false);
    }, [])


    return (
        <div data-testid="link-expiry-root" className="link-expiry-root">
            <Grid item md={12} xs={12} className="link-expiry-gridSeparation">
            <div className="link-expiry-success">Your link to register has expired.</div>
            <div className="link-expiry-content">Please contact your advisor to generate a new invitation to register.</div>
            </Grid>           
        </div>
    );
}

export default LinkExpiredComponent;
