import React, { useContext, useEffect } from 'react';
import "../style/MfaTypeComponentStyle.css"
import Grid from '@mui/material/Grid';
import {Button, RadioGroup, INPUT_VARIANT, Progress} from '@manulife/mux';
import { useState } from 'react';
import { MyContext } from '../Context/MyContext';
import Service from '../Services/Service';
import Constants from '../Utilities/Constants';
import Codes from '../Resources/Config/Codes.json';


const MfaTypeComponent = (props) => {

    const {setPage, partyData, adParams, setAdParams} = props;
    const context = useContext(MyContext);
    const [formValues, setFormValues] = useState({
        mfaType: "SMS"
    });

    const [isLoading, setLoading] = useState(false);

    useEffect(()=> {
        context.setSessionTimeoutEnable(true);
    }, [])

    function changeHandler(name, value) {
        const newFormValues = { ...formValues, [name]: value };
        setFormValues(newFormValues);
    }

    function submit(){
        if(isLoading) return;
        setAdParams({...adParams, mfaType: formValues.mfaType})
        sendMfaCode(); 
    }

    function sendMfaCode(){
        context.setNotification({...context.notification, isVisible: false, title: "", message: "", type: "info" })

        const payload = {
            "guid": partyData.guid,
            "phonenumber": partyData.phoneNumber,
            "type": formValues.mfaType
        };

        setLoading(true);
        Service.generateMfaCodeCall(payload)
        .then((response)=> {
            setLoading(false);
            if(response.code === Constants.SUCCESS_CODE_200){
                setPage(Constants.PAGE_MFA_VARIFY_2);
                context.setNotification({...context.notification, isVisible: false, title: "", message: "", type: "info", count: 0 })
            } else if(response.code === "9033"){
                setAdParams({...adParams, validateMfaError: false});
                setPage(Constants.PAGE_MFA_ERROR_7);
                context.setNotification({...context.notification, isVisible: false, title: "", message: "", type: "info", count: 0 })
            } else if(response.code === "9025"){
                setPage(Constants.PAGE_ERROR_5);
                context.setNotification({...context.notification, isVisible: false, title: "", message: "", type: "info", count: 0 })
            } else{
                // Error codes 9022, 9052, 2002
                let count = context.notification.count ? context.notification.count+1 : 1;
                if(count == 3){
                    setPage(Constants.PAGE_ERROR_5);
                    context.setNotification({...context.notification, isVisible: false, title: "", message: "", type: "info", count: 0 })
                } else {
                    let title = Codes.title.something_went_wrong;
                    let message = Codes.message.something_went_wrong;
                    context.setNotification({...context.notification, isVisible: true, title: title, message: message, type: "info", count: count })
                }
            }
        });
    }

    const createLabelText = () => {
        let mfaValue = [{value: 'SMS', label:"Text me at _CC_ XXX XXX _PN_"}, 
                        {value: 'VOICE', label:"Call me at _CC_ XXX XXX _PN_"}]

        if(partyData?.phoneNumber){
            let phoneNumber = partyData.phoneNumber;

            let countryCode = "";
            let last4Digit = "";

            if(partyData.phoneNumber.includes("(") && partyData.phoneNumber.includes(")")){

                let tempArr = phoneNumber.split(")");
                phoneNumber = tempArr[1].replace(/[^\w]/gi, '');
                countryCode = tempArr[0].replace(/[^\w]/gi, '');

                if(!countryCode.startsWith("+")) countryCode = "+"+countryCode
                last4Digit = phoneNumber.substring(phoneNumber.length - 4); 
                         
            } else {

                last4Digit = phoneNumber.substring(phoneNumber.length - 4);
            }

            mfaValue[0].label = mfaValue[0].label.replace("_CC_", countryCode);
            mfaValue[0].label = mfaValue[0].label.replace("_PN_", last4Digit);
            
            mfaValue[1].label = mfaValue[1].label.replace("_CC_", countryCode);
            mfaValue[1].label = mfaValue[1].label.replace("_PN_", last4Digit);            
        }

        return mfaValue
    }
    
    return (
        <Grid data-testid="mfaType-root" container md={12} xs={12} spacing={0}>   
        
            <span className="mfaType-header-title">Register for Manulife Bermuda eDocuments</span>   
            <div className="mfaType-top-content">
                <p className='mfa-content'>In order to register we need to verify your identity by providing an authorization code to the number we have on record for you. This is to ensure your information is protected and to only authorize access to you.</p>
                <br />
                <p className='mfa-content'>Please choose one of the contact methods below to receive a confirmation code: Message and data rates may apply</p> 
            </div>      
            <Grid item md={12} xs={12} className="mfaType-gridSeparation">
                <RadioGroup
                    name="mfaType"
                    onChange={e => changeHandler("mfaType", e)}
                    className={"mfa-radio-group"}
                    selected={formValues.mfaType}
                    direction="column"
                    required={true}
                    values={createLabelText()}
                    customStyle={
                        {
                            legendStyle: {
                                display: "none"
                            },
                            rootStyle: {
                                marginLeft: "-5px",
                                marginTop: "-10px"
                            },
                            labelStyle: {
                                fontFamily: "Manulife JH Sans",
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "8px",
                                lineHeight: "20px",
                                color: "#282B3E",
                                alignSelf: "center",
                                marginTop: "26px"
                            }
                        }
                    }
                />
            </Grid>

            <Grid item md={12} xs={12} className="mfaType-progress-button-container mfaType-gridSeparation">
                <Button
                    name="submit"
                    data-testid="mfaType-submitButton"
                    customStyle={{
                        buttonStyle: {
                            display: "flex",
                            flexDirection: "column",
                            width: "150px",
                            height: "60px",
                            minWidth: "0px",
                            borderRadius: "0px",
                            padding: "0px"
                        }
                    }}
                    id="submitButton"
                    onClick={e => submit()}
                    disabled={false}>Send Code</Button>
                {isLoading && <div className="progress-container">
                            <Progress                             
                                data-testid="mfaType-loader"
                                isFloating={false} customStyle={{
                                spinnerStyle: {
                                    position: "relative",
                                    height: "40px",
                                    width: "40px"
                                }
                            }} />
                            <p className="loading-text">Please wait while we process your information</p>
                        </div>
                }
            </Grid>

            <Grid item md={12} xs={12}>
                <div>
                    <p className='mfa-content strong'>Please note:</p>
                    <br/>                    
                    <p className='mfa-content'>There is no charge from Manulife, but message and data rates may apply.</p>
                    <br/>
                    <p className='mfa-content'>Delivery of the security code may be delayed due to service outages affecting your telephone device, wireless or internet provider;</p> 
                    <p className='mfa-content'>technology failures; system capacity limitations and other connection issues you or your device may be experiencing.</p>
                    <br/>
                    <p className='mfa-content'>If you have any questions or need assistance, please contact your advisor.</p>
                    <br/>
                    <p className='mfa-content'>Please be aware that anyone with access to your telephone device may be able to log in into your account.</p>
                    <br/>
                    <p className='mfa-content'>We value your privacy. For details about our Privacy Policy <a className="content-link" href={Constants.URL_PRIVACY_PAGE} target="_blank">click here</a>.</p>
                </div>   
            </Grid>
        </Grid>
    )
}
export default MfaTypeComponent;