import React from 'react'
import "../style/LeftComponentStyle.css"

const LeftComponent = (props) => {

    return (
        <div className="left-root" />
    );
}

export default LeftComponent;
