// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.link-expiry-root{
    
}

.link-expiry-success{
    font-family: Manulife JH Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 58px;
    color: #202336;
    margin-bottom: 36px;
}
.link-expiry-content{
    font-family: Manulife JH Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;color: #202336;
}


.link-expiry-gridSeparation {
    margin-bottom: 32px !important;
}

.link-expiry-progress-button-container{
    display:  flex;
    flex-direction: row;
}

.link-expiry-progress-container{
    display: flex;
    align-items: center;
    margin-left: 10px;
}
.link-expiry-loading-text{
    font-family: Manulife JH Sans;
    font-size: 12px;
    line-height: 20px;
    display: inline-block;
    margin-left: 20px;
}`, "",{"version":3,"sources":["webpack://./src/style/LinkExpiredComponentStyle.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;IACI,6BAA6B;IAC7B,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,mBAAmB;AACvB;AACA;IACI,6BAA6B;IAC7B,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB,CAAC,cAAc;AACpC;;;AAGA;IACI,8BAA8B;AAClC;;AAEA;IACI,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,iBAAiB;AACrB;AACA;IACI,6BAA6B;IAC7B,eAAe;IACf,iBAAiB;IACjB,qBAAqB;IACrB,iBAAiB;AACrB","sourcesContent":[".link-expiry-root{\r\n    \r\n}\r\n\r\n.link-expiry-success{\r\n    font-family: Manulife JH Sans;\r\n    font-style: normal;\r\n    font-weight: 600;\r\n    font-size: 48px;\r\n    line-height: 58px;\r\n    color: #202336;\r\n    margin-bottom: 36px;\r\n}\r\n.link-expiry-content{\r\n    font-family: Manulife JH Sans;\r\n    font-style: normal;\r\n    font-weight: 400;\r\n    font-size: 16px;\r\n    line-height: 26px;color: #202336;\r\n}\r\n\r\n\r\n.link-expiry-gridSeparation {\r\n    margin-bottom: 32px !important;\r\n}\r\n\r\n.link-expiry-progress-button-container{\r\n    display:  flex;\r\n    flex-direction: row;\r\n}\r\n\r\n.link-expiry-progress-container{\r\n    display: flex;\r\n    align-items: center;\r\n    margin-left: 10px;\r\n}\r\n.link-expiry-loading-text{\r\n    font-family: Manulife JH Sans;\r\n    font-size: 12px;\r\n    line-height: 20px;\r\n    display: inline-block;\r\n    margin-left: 20px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
