import React, { useContext, useEffect, useState } from 'react'
import "../style/RegistrationComponenttStyle.css"
import { ReactComponent as PasswordVisible } from '../Resources/Images/password_visible.svg';
import { ReactComponent as PasswordHide } from '../Resources/Images/password_hide.svg';
import { Grid } from '@mui/material';
import { Checkbox, ActionButton, Button, Dropdown, DROPDOWN_VARIANT, INPUT_VARIANT, Progress, TextInput } from '@manulife/mux';
import { MyContext } from '../Context/MyContext';
import { getErrorMessage, isEmpty } from '../Utilities/Validations';
import Service from '../Services/Service';
import Constants from '../Utilities/Constants';
import Codes from '../Resources/Config/Codes.json';
import SecretQuestions from '../Resources/Config/SecretQuestions.json';
import { doNothing, getQueryParams } from '../Utilities/Utility';
import PasswordStrengthValidator from './PasswordStrengthValidator';
import { hasNumbers, hasUpper, hasLower, hasSpecial, hasEight, isValidPasword } from '../Utilities/ValidatePassword';
import InputError from './InputError';


const RegistrationComponent = (props) => {

    const {mfaDisabled ,setPage, partyData, adParams, setAdParams} = props;
    let params = getQueryParams();

    const context = useContext(MyContext);

    const [formValues, setFormValues] = useState({
        userName: "", 
        pwd: "",
        confirmPwd: "",
        securityQuestion: null,
        answer: "",
        tcChecked: false
    });

    const [formErrors, setFormErrors] = useState({
        userName: { id: 'userName', errMsg: '', isError: false },
        pwd: { id: 'pwd',errMsg: '', isError: false},
        confirmPwd: { id: 'confirmPwd', errMsg: '', isError: false},
        securityQuestion: { id: 'securityQuestion', errMsg: '', isError: false },
        answer: { id: 'answer', errMsg: '', isError: false },
        tcChecked: { id: 'tcChecked', errMsg: '', isError: false }
    });

    const [isLoading, setLoading] = useState(false);
    const [isPasswordVisible, setPasswordVisible] = useState(false);
    const [isCPasswordVisible, setCPasswordVisible] = useState(false);
    const [isCheckingPassStrength, setCheckingPassStrength] = useState(false);
    const [isPasswordStrengthPassed, setPasswordStrengthPassed] = useState(false);
    const [passwordValidated, setPasswordValidated] = useState(false);
    const [password, setPassword] = useState("");

    
    useEffect(()=> {
        context.setSessionTimeoutEnable(true);
    }, [])
    
    function changeHandler(name, value) {
        const newFormValues = { ...formValues, [name]: value };
        setFormValues(newFormValues);
        setFormErrorOnChange(name, value);
    }

    function setFormErrorOnChange(name, value){

        if(formErrors[name].isError != !value ){
            let formError = {id: name, errMsg: getErrorMessage(name), isError: !value };
            setFormErrors({...formErrors, [name]: formError});
        }
    }

    
    function onPasswordValidatedResponse(data, error){
        
        setCheckingPassStrength(false);
        setPassword(formValues.pwd);
        if(error){
            // setSystemErrorCount(systemErrorCount + 1);
            return;
        }

        if (data.code === "0000") {
            // setSystemErrorCount(0);
            setPasswordStrengthPassed(true);
            checkAndSetUIPasswordErrors(true); // validatepassword success true
        } else if(data.code === "9001" || data.code === "9003"){
            // setSystemErrorCount(systemErrorCount + 1);
            setPasswordStrengthPassed(false);
        } else if( data.code === "9020"){
            setPasswordStrengthPassed(false);
            if(isValidPasword(formValues.pwd)){
                setFormErrors({...formErrors, ...{pwd: { id: 'pwd', errMsg: data.message, isError: true, errorCode: data.code}}});
            } else{                
                checkAndSetUIPasswordErrors(false); // validatepassword success true
            }
        } else if(data.code === "2001" || data.code === "2002" ||
                data.code === "2003" || data.code === "2004"){
            setFormErrors({...formErrors, ...{pwd: { id: 'pwd', errMsg: data.message, isError: true, errorCode: data.code}}});
            setPasswordStrengthPassed(false);
        }
    }

    function checkAndSetUIPasswordErrors(validPasswordSuccess){
        let password = formValues.pwd;
        let errors = [];
        errors.push(hasNumbers(password));
        errors.push(hasUpper(password));
        errors.push(hasLower(password));
        errors.push(hasSpecial(password));
        errors.push(hasEight(password));

        errors = errors.filter((item)=> item != null);

        if(errors.length > 0){
            let errorMsg = errors.length == 1 && validPasswordSuccess ? errors[0] : "Please be sure to fulfill all password requirements.";
            setFormErrors({...formErrors, ...{pwd: { id: 'pwd', errMsg: errorMsg, isError: true }}});
        }
    }
    
    function onPasswordCheck() {
        let password = formValues.pwd

        if(!password){
            setPassword(password);
            return;            
        }

        if(!isCheckingPassStrength){
            let payload = {
                userid: formValues.userName.trim(),
                password: password,
                guid: partyData.guid
            }
            
            setPasswordValidated(isValidPasword(password))
            setCheckingPassStrength(true)
            Service.validatePassword(payload)
            .then((response)=> onPasswordValidatedResponse(response))
            .catch((error)=> onPasswordValidatedResponse(null, error))
        }
}

    function submit(){
        if(isLoading) return;

        context.setNotification({...context.notification, isVisible: false})

        if(validate()){
            createUser();
        }
    }

    
    function createUser(){
        const payload = {
            "guid": partyData.guid,
            "token": params.jwt,
            "username": formValues.userName,
            "password": formValues.pwd,
            "businesscategory": formValues.securityQuestion+"::"+formValues.answer
        };

        setLoading(true);
        Service.createUserCall(payload)
        .then((response)=> {
            setLoading(false);
            if(response.code == Constants.SUCCESS_CODE_2001){
                setPage(Constants.PAGE_CONGRATULATION_PAGE_4);
                setAdParams({...adParams, userName: formValues.userName})
                context.setNotification({...context.notification, isVisible: false, title: "", message: "", type: "info", count: 0 })
            } else if(response.code == Constants.INVALIDE_TOKEN_2000){
                setPage(Constants.PAGE_LINK_EXPIRED_9);
                context.setNotification({...context.notification, isVisible: false, title: "", message: "", type: "info", count: 0 })
            } else if(response.code === "9012"){
                setFormErrors({...formErrors, userName: {id: "userName", errMsg: getErrorMessage("userNameDuplicate"), isError: true}})
                context.setNotification({...context.notification, isVisible: false, title: "", message: "", type: "info", count: 0 })
            } else if(response.code === "9020"){
                setPasswordStrengthPassed(false);
                setPasswordValidated(false);
                setFormErrors({...formErrors, pwd: {id: "pwd", errMsg: response.message, isError: true}})
                context.setNotification({...context.notification, isVisible: false, title: "", message: "", type: "info", count: 0 })
            } else{
                // Error codes 400, 9004, 9052, 9002, 9019, 9014, 9001, 9017, 9015, 
                let count = context.notification.count ? context.notification.count+1 : 1;
                if(count >= 3){
                    setPage(Constants.PAGE_ERROR_5);
                    context.setNotification({...context.notification, isVisible: false, title: "", message: "", type: "info", count: 0 })
                } else {
                    let title = Codes.title.registration_failed;
                    let message = count === 1 ? Codes.message.registration_failed_1 : Codes.message.registration_failed_2;
                    context.setNotification({...context.notification, isVisible: true, title: title, message: message, type: "info", count: count })
                }
            }
        });
    }

    
    function validateUsernameAjaxCall(){
        if(isLoading) return;

        if(validateUserName()){
            const payload = {
                "guid":partyData.guid,
                "username": formValues.userName?.trim()
            };
    
            setLoading(true);
            Service.validateUsername(payload)
            .then((response)=> {
                setLoading(false);
                if(response.code === Constants.SUCCESS_CODE_0000){
                    console.log("Username validation Success: 0000");
                } else if(response.code === "9012"){
                    // already taken
                    let formError = {id: 'userName', errMsg: getErrorMessage("userNameDuplicate"), isError: true };
                    setFormErrors({...formErrors, userName: formError})
                } else{
                    // Error Codes 9052, 9002, 9014, 9001,
                    let count = context.notification.count ? context.notification.count+1 : 1;
                    if(count >= 3){
                        setPage(Constants.PAGE_ERROR_5);
                        context.setNotification({...context.notification, isVisible: false, title: "", message: "", type: "info", count: 0 })
                    } else {
                        let title = Codes.title.something_went_wrong
                        let message = Codes.message.something_went_wrong;
                        context.setNotification({...context.notification, isVisible: true, title: title, message: message, type: "info", count: count })
                    }
                }
            });
        }        
    }

    function validate(){
        let isValid = true;
        let tempFormErrors = {};
       
        if(isEmpty(formValues.userName)){
            tempFormErrors["userName"] = {id: 'userName', errMsg: getErrorMessage("userName"), isError: true };
            isValid = false;
        } else if(formErrors.userName.isError){
            tempFormErrors["userName"] = {id: 'userName', errMsg: getErrorMessage("userNameDuplicate"), isError: true };
            isValid = false;
        } else {
            tempFormErrors["userName"] = {id: 'userName', errMsg: "", isError: false };
        }
        
        if(isEmpty(formValues.pwd)){
            tempFormErrors["pwd"] = {id: 'pwd', errMsg: getErrorMessage("pwd"), isError: true };
            isValid = false;
        } else{
            tempFormErrors["pwd"] = {id: 'pwd', errMsg: "", isError: false };
        }

        if(isEmpty(formValues.confirmPwd)){
            tempFormErrors["confirmPwd"] = {id: 'confirmPwd', errMsg: getErrorMessage("confirmPwd"), isError: true };
            isValid = false;
        }else if(formValues.pwd !== formValues.confirmPwd){
            tempFormErrors["confirmPwd"] = {id: 'confirmPwd', errMsg: getErrorMessage("confirmPwdNotMatched"), isError: true };
            if(!tempFormErrors?.pwd?.isError){
                tempFormErrors["pwd"] = {id: 'pwd', errMsg: getErrorMessage("confirmPwdNotMatched"), isError: true };
            }
            isValid = false;
        } else {
            tempFormErrors["confirmPwd"] = {id: 'confirmPwd', errMsg: "", isError: false };
        }

        if(isEmpty(formValues.securityQuestion)){
            tempFormErrors["securityQuestion"] = {id: 'securityQuestion', errMsg: getErrorMessage("securityQuestion"), isError: true };
            isValid = false;
        } else {
            tempFormErrors["securityQuestion"] = {id: 'securityQuestion', errMsg: "", isError: false };
        }

        if(isEmpty(formValues.answer)){
            tempFormErrors["answer"] = {id: 'answer', errMsg: getErrorMessage("answer"), isError: true };
            isValid = false;
        } else {
            tempFormErrors["answer"] = {id: 'answer', errMsg: "", isError: false };
        }

        if(!formValues.tcChecked){
            tempFormErrors["tcChecked"] = {id: 'tcChecked', errMsg: getErrorMessage("tcChecked"), isError: true };
            isValid = false;
        } else {
            tempFormErrors["tcChecked"] = {id: 'tcChecked', errMsg: "", isError: false };
        }

        if(!passwordValidated || !isPasswordStrengthPassed || isCheckingPassStrength){
            isValid = false;
        }

        setFormErrors({...formErrors, ...tempFormErrors})

        return isValid;
    }

    function validateUserName(){
       
        if(isEmpty(formValues.userName)){
            setFormErrors({...formErrors, userName: {id: 'userName', errMsg: getErrorMessage("userName"), isError: true }});
            return false;
        }else if(formValues.userName.length<=8){
            setFormErrors({ ...formErrors, userName: { id: 'userName', errMsg: getErrorMessage("userNameLen"), isError: true } });
            return false;
        } else if(!checkSpecialChar()){
            setFormErrors({ ...formErrors, userName: { id: 'userName', errMsg: getErrorMessage("userNameSpecialChars"), isError: true } });
            return false;
        }else{
            return true;
        }
    }

    function checkSpecialChar(){     
        let text = formValues.userName;
        let pattern = /^[A-Za-z0-9_@.-]*$/;   
        let result = text.match(pattern);
        //console.log(result);
        if(result !=null){
            return true;
        }else{
            return false;
        }
    }

    function buildDropDownMonth(){

        let questions = SecretQuestions.questions;
        let questionObj = []
        for (let i = 0; i < questions.length; i++) {
            questionObj.push({ value: (i + 1).toString(), label: questions[i].toString() })
        }
        return questionObj;
    }

    const commanCss = "registration-gridSeparation1 myinput";
    return (
        <div className="registration-root">
            <Grid data-testid="registration-root" container md={12} xs={12} spacing={0}>
                <span className="registration-header-title">Register for eDocuments</span>   
                <Grid item md={12} xs={12} className={commanCss+(formErrors.userName.isError ? " error" : "")}>
                    <div className='registration-input-item'>
                        <label data-testid="registration-usernameLabel" className="registration-labelStyle">
                            Create username
                        </label>
                        <TextInput variant={INPUT_VARIANT.BASIC_LINE}
                            id='userName'
                            data-testid="registration-userName"
                            maxLength={50}
                            value={formValues.userName}
                            errors={[formErrors.userName]}
                            onKeyDown={doNothing}
                            placeholder="Choose your user ID"
                            onChange={e => changeHandler("userName", e)}
                            onBlur={() => validateUsernameAjaxCall()}
                            callouts={true}
                            customStyle={{
                                rootStyle: {
                                    width: "100%",
                                },
                                inputStyle: {
                                    padding: '0px'

                                }
                            }} name="userName"
                        />
                    </div>
                    {(formErrors.userName.isError) && <InputError inputId="userName" formErrors={formErrors} /> }
                </Grid>

                <Grid item md={12} xs={12} className={"myinput"+(formErrors.pwd.isError ? " error" : "")}>
                    <div className='registration-input-item'>                        
                        <label data-testid="registration-createPasswordLabel" className="registration-labelStyle">
                            Create password
                        </label>
                        <TextInput variant={INPUT_VARIANT.BASIC_LINE}
                            id='pwd'
                            data-testid="registration-password"
                            maxLength={50}
                            value={formValues.pwd}
                            errors={[formErrors.pwd]}
                            onKeyDown={doNothing}
                            placeholder="Choose your password"
                            onChange={e => {
                                if(passwordValidated) setPasswordValidated(false);
                                changeHandler("pwd", e)}}
                            onBlur={onPasswordCheck}
                            onFocus={()=> {
                                setPasswordValidated(false);
                                setFormErrors({...formErrors, ...{pwd: { id: 'pwd', errMsg: "", isError: false }}});
                            }}
                            callouts={true}
                            type={isPasswordVisible ? "text" : "password"}
                            isIconClickable={true}
                            icon={<ActionButton
                                ariaLabel="Edit"
                                icon={isPasswordVisible ? <PasswordHide/> : <PasswordVisible /> }
                                onClick={e => setPasswordVisible(!isPasswordVisible)}                           
                            />}
                            customStyle={{
                                rootStyle: {
                                    width: "100%",
                                },
                                inputStyle: {
                                    padding: '0px'

                                }
                            }} name="pwd"
                        />
                    </div>
                </Grid>
                
                <Grid item md={12} xs={12} className="registration-gridSeparation2">
                <div className='registration-input-item'>    
                    <PasswordStrengthValidator 
                        pwd={password}
                        onPasswordCheck={onPasswordCheck}
                        formErrors={formErrors} 
                        isCheckingPassStrength={isCheckingPassStrength} 
                        passwordValidated={passwordValidated}
                        isPasswordStrengthPassed={isPasswordStrengthPassed}
                    />
                </div>
                </Grid>

                <Grid item md={12} xs={12} className={"registration-gridSeparation2 myinput"+(formErrors.confirmPwd.isError ? " error" : "")}>
                    <div className='registration-input-item'>  
                        <label data-testid="registration-confirmPasswordLabel" className="registration-labelStyle">
                            Confirm password
                        </label>
                        <TextInput variant={INPUT_VARIANT.BASIC_LINE}
                            id='confirmPwd'
                            data-testid="registration-confirmPassword"
                            maxLength={50}
                            placeholder="Re-enter your password"
                            value={formValues.confirmPwd}
                            errors={[formErrors.confirmPwd]}
                            onKeyDown={doNothing}
                            type={isCPasswordVisible ? "text" : "password"}
                            isIconClickable={true}
                            icon={<ActionButton
                                ariaLabel="Edit"
                                icon={isCPasswordVisible ? <PasswordHide/> : <PasswordVisible /> }
                                onClick={e => setCPasswordVisible(!isCPasswordVisible)}                           
                            />}
                            onChange={e => changeHandler("confirmPwd", e)}
                            callouts={true}
                            customStyle={{
                                rootStyle: {
                                    width: "100%",
                                },
                                inputStyle: {
                                    padding: '0px'

                                }
                            }} name="confirmPwd"
                        />
                    </div>
                    {(formErrors.confirmPwd.isError) && <InputError inputId="confirmPwd" formErrors={formErrors} /> }
                </Grid>
                
                <Grid item md={12} xs={12} className={commanCss+(formErrors.securityQuestion.isError ? " error" : "")}>
                    <div className='registration-input-item'>
                                
                        <label data-testid="registration-securityQuestionLabel" className="registration-labelStyle">
                            Security question
                        </label>
                        <Dropdown
                            id="securityQuestion"
                            data-testid="registration-securityQuestion"
                            customStyle={{
                                rootStyle: {
                                    width: "100%",
                                    paddingRight: "0px"
                                }
                            }}
                            value={formValues.securityQuestion}
                            ariaRequired={true}
                            errors={[formErrors.securityQuestion]}
                            onChange={e => changeHandler("securityQuestion", e)}
                            callouts={true}
                            variant={DROPDOWN_VARIANT.BASIC_LINE}
                            dropdownItems={buildDropDownMonth()}
                            placeholder="Select your question"
                            name='securityQuestion' />
                    </div>
                    {(formErrors.securityQuestion.isError) && <InputError inputId="securityQuestion" formErrors={formErrors} /> }
                </Grid>
                
                <Grid item md={12} xs={12} className={commanCss+(formErrors.answer.isError ? " error" : "")}>
                    <div className='registration-input-item'>
                                
                        <label className="registration-labelStyle">
                            Answer
                        </label>
                        <TextInput variant={INPUT_VARIANT.BASIC_LINE}
                            id='answer'
                            data-testid="registration-answer"
                            maxLength={50}
                            placeholder="Enter your answer"
                            value={formValues.answer}
                            errors={[formErrors.answer]}
                            onKeyDown={doNothing}
                            onChange={e => changeHandler("answer", e)}
                            callouts={true}
                            customStyle={{
                                rootStyle: {
                                    width: "100%",
                                },
                                inputStyle: {
                                    padding: '0px'

                                }
                            }} name="answer"
                        />
                    </div>
                    {(formErrors.answer.isError) && <InputError inputId="answer" formErrors={formErrors} /> }
                </Grid>
                
                <span className="registration-tc-consent-text">You must <a className="registration-link" href={Constants.URL_CONSENT_DELEIEVRY_PAGE} target="_blank">click here</a> to read the Consent to Electronic Delivery before proceeding.</span>   

                <Grid item md={12} xs={12} className="registration-gridSeparation2">
                    <div className="registration-terms-conditions">
                        <Checkbox
                            id="tcChecked"
                            data-testid="registration-tcChecked"
                            className="tc-checkbox"
                            required={true}
                            callouts={true}
                            checked={formValues.tcChecked}
                            errors={[formErrors.tcChecked]}
                            onChange={e => changeHandler("tcChecked", e)}
                            name="tcChecked"
                        />
                        <div className="tcChecked-waiver-text"><label htmlFor='tcChecked'>I confirm to Manulife Bermuda that I (i) have read, understand and agree to the Consent in the link above, including electronic delivery of communications, notices and disclosures via email alert to the email address used to invite you to register when a document has been delivered and is available in the portal, and (ii) can receive emails with hyperlinks to websites.</label></div>
                    </div>
                    {(formErrors.tcChecked.isError) && <InputError inputId="tcChecked" formErrors={formErrors} /> }
                </Grid>

                <Grid item md={12} xs={12} className="registration-gridSeparation2">
                    <div className="registration-progress-button-container">
                            <div className='registration-button-container'>
                                {
                                    !mfaDisabled &&
                                    <Button
                                        name="back"
                                        data-testid="registration-backButton"
                                        className="registration-button"
                                        customStyle={{
                                            buttonStyle: {
                                                width: "150px",
                                                height: "60px",
                                                minWidth: "0px",
                                                borderRadius: "0px",
                                                marginBottom: "20px"
                                            }
                                        }}
                                        id="backButton"
                                        onClick={e => setPage(Constants.PAGE_MFA_VARIFY_2)}
                                        disabled={false}>Back
                                    </Button>
                                }
                                <Button
                                    variant={mfaDisabled ? "primary" : "secondary"}
                                    name="submit"
                                    className="registration-button"
                                    data-testid="registration-submitButton"
                                    customStyle={{
                                        buttonStyle: {
                                            width: "150px",
                                            height: "60px",
                                            minWidth: "0px",
                                            borderRadius: "0px",
                                            marginLeft: mfaDisabled ? "0px" : "36px",
                                            marginBottom: "20px"
                                        }
                                    }}
                                    id="submitButton"
                                    onClick={e => submit()}
                                    disabled={false}> Continue
                                </Button>
                            </div>
                        {
                            isLoading && 
                                <div data-testid="registration-loader" className="registration-progress-container">
                                    <Progress                            
                                        
                                        isFloating={false} customStyle={{
                                        spinnerStyle: {
                                            position: "relative",
                                            height: "40px",
                                            width: "40px"
                                        }
                                    }} />
                                    <p className="registration-loading-text">Please wait while we process your information</p>
                                </div>
                        }
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default RegistrationComponent;
