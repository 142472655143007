import React from "react";

const contextDefault = {
    notification: {},
    setNotification: () => console.log("setNotification default"),
    sessionTimeoutEnable: true,
    setSessionTimeoutEnable: () => console.log("setSessionTimeoutEnable default")
}

export const MyContext = React.createContext(contextDefault);
  