// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-root{
    display: block;
}
.container{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    background: #FAFAFA;
}

.manulife-icon-container{
    display: flex;
    width: 80px;
    height: 94px;
    align-items: center;
    justify-content: center;
    background-color: #00A758;
    margin-right: 70px;
}

.header-left-logo{
    display: flex;
    align-items: center;
    
}
.button-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2px 20px;
    margin-right: 50px;  
    border: 2px solid #EC6453;
    cursor: pointer;
    background-color: #FFFFFF;  
}

.button-text{
    font-family: Manulife JH Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0px;
    text-align: center;
    color: #000000;
}

/* .button-container:hover{
    background-color: #EC6453;  
    color: #FFFFFF;
} */`, "",{"version":3,"sources":["webpack://./src/style/HeaderComponentStyle.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,WAAW;IACX,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,uBAAuB;IACvB,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;;AAEvB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB;IAClB,yBAAyB;IACzB,eAAe;IACf,yBAAyB;AAC7B;;AAEA;IACI,6BAA6B;IAC7B,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;IAClB,cAAc;AAClB;;AAEA;;;GAGG","sourcesContent":[".header-root{\r\n    display: block;\r\n}\r\n.container{\r\n    display: flex;\r\n    justify-content: space-between;\r\n    width: 100%;\r\n    align-items: center;\r\n    background: #FAFAFA;\r\n}\r\n\r\n.manulife-icon-container{\r\n    display: flex;\r\n    width: 80px;\r\n    height: 94px;\r\n    align-items: center;\r\n    justify-content: center;\r\n    background-color: #00A758;\r\n    margin-right: 70px;\r\n}\r\n\r\n.header-left-logo{\r\n    display: flex;\r\n    align-items: center;\r\n    \r\n}\r\n.button-container{\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    padding: 2px 20px;\r\n    margin-right: 50px;  \r\n    border: 2px solid #EC6453;\r\n    cursor: pointer;\r\n    background-color: #FFFFFF;  \r\n}\r\n\r\n.button-text{\r\n    font-family: Manulife JH Sans;\r\n    font-size: 14px;\r\n    font-weight: 600;\r\n    line-height: 26px;\r\n    letter-spacing: 0px;\r\n    text-align: center;\r\n    color: #000000;\r\n}\r\n\r\n/* .button-container:hover{\r\n    background-color: #EC6453;  \r\n    color: #FFFFFF;\r\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
