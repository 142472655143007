import React, { useContext, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { Button } from '@manulife/mux';
import Utility from '../Utilities/Utility';
import Constants from '../Utilities/Constants';
import "../style/MfaErrorPage.css";
import { MyContext } from '../Context/MyContext';

const MfaErrorPage = props => {

    const {adParams} = props;

    
    const context = useContext(MyContext);

    useEffect(()=> {
        context.setSessionTimeoutEnable(false);
    }, [])

    function redirectToHome(){
        Utility.redirectTo(Constants.URL_MLB_HOME_PAGE, [Constants.URL_MLB_HOME_PAGE])
    }

    function gotofirstpage(){
        window.location.reload();
    }

    return (
        <div className="mfa-error-root">
            <Grid container md={8} xs={12} className="mfa-error-gridSeparation">
                <span className="mfa-error-errorHeaderStyles">We're sorry.</span>
            </Grid>
            <Grid container md={8} xs={12} className="mfa-error-gridSeparation">
                {
                    adParams.validateMfaError ? <span className="mfa-error-subheaderStyles">The number of validation attempts has been exceeded.</span>
                        : <span className="mfa-error-subheaderStyles">Looks like we're having some trouble generating your code. Please try again later.</span>
                }
            </Grid>
            <Grid container xs={12} className="mfa-error-buttonPadding">
                <Grid item md={8} xs={12}>
                    {
                        adParams.validateMfaError ?
                            <Button
                                customStyle={{
                                    buttonStyle: {
                                        width: "278px" ,
                                        height: "55px"
                                    }
                                }}
                                name="startOver"
                                id="startOver"
                                onClick={() => gotofirstpage()}
                            ><div className="mfa-error-buttonTextStyles">Start over</div></Button>
                            : <Button
                                customStyle={{
                                    buttonStyle: {
                                        width: "266px",
                                        height: "55px"
                                    }
                                }}
                                name="ReturnToJHButton"
                                id="ReturnToJHButton"
                                onClick={() => redirectToHome()}
                            ><div className="mfa-error-buttonTextStyles">Go to Manulife Bermuda</div></Button>
                    }

                </Grid>
            </Grid>
        </div>
    );
};

export default MfaErrorPage;