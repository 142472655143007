// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-root{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #ffffffaa;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
}


.progress-container{
    display: flex;
    align-items: center;
    background-color: #ffffffaa;
}
.loading-text{
    font-family: Manulife JH Sans;
    font-size: 16px;
    line-height: 20px;
    display: inline-block;
    margin-left: 20px;
    font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/style/LoaderComponentStyle.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,aAAa;IACb,2BAA2B;IAC3B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,cAAc;AAClB;;;AAGA;IACI,aAAa;IACb,mBAAmB;IACnB,2BAA2B;AAC/B;AACA;IACI,6BAA6B;IAC7B,eAAe;IACf,iBAAiB;IACjB,qBAAqB;IACrB,iBAAiB;IACjB,gBAAgB;AACpB","sourcesContent":[".loader-root{\r\n    position: fixed;\r\n    top: 0;\r\n    left: 0;\r\n    width: 100%;\r\n    height: 100vh;\r\n    background-color: #ffffffaa;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    z-index: 99999;\r\n}\r\n\r\n\r\n.progress-container{\r\n    display: flex;\r\n    align-items: center;\r\n    background-color: #ffffffaa;\r\n}\r\n.loading-text{\r\n    font-family: Manulife JH Sans;\r\n    font-size: 16px;\r\n    line-height: 20px;\r\n    display: inline-block;\r\n    margin-left: 20px;\r\n    font-weight: 400;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
