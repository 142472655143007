import React, { useContext, useEffect } from 'react';
import "../style/MfaVerificationComponentStyle.css"
import Grid from '@mui/material/Grid';
import {Button, INPUT_VARIANT, Progress, TextInput} from '@manulife/mux';
import { useState } from 'react';
import { isEmpty, getErrorMessage } from '../Utilities/Validations';
import { MyContext } from '../Context/MyContext';
import Service from '../Services/Service';
import Constants from '../Utilities/Constants';
import Codes from '../Resources/Config/Codes.json';
import InputError from './InputError';


const MfaVerificationComponent = (props) => {

    const {setPage, partyData, adParams, setAdParams} = props;
    const context = useContext(MyContext);
    const [formValues, setFormValues] = useState({
        mfaCode: ""
    });

    const [formErrors, setFormErrors] = useState({
        mfaCode: { id: 'mfaCode', errMsg: '', isError: false }
    });

    const [isLoading, setLoading] = useState(false);

    useEffect(()=> {
        context.setSessionTimeoutEnable(true);
    }, [])

    function changeHandler(name, value) {
        const newFormValues = { ...formValues, [name]: value };
        setFormValues(newFormValues);
        setFormErrorOnChange(name, value);
    }
    
    function setFormErrorOnChange(name, value){

        if(formErrors[name].isError != !value ){
            console.log("setFormErrorOnChange called");
            let formError = {id: name, errMsg: getErrorMessage(name), isError: !value };
            setFormErrors({...formErrors, [name]: formError});
        }
    }

    function submit(){
        if(isLoading) return;

        context.setNotification({...context.notification, isVisible: false})

        if(validate()){
            varifyMfaCode(); 
        }
    }
    
    function resendMfaCode(){
        context.setNotification({...context.notification, isVisible: false});
        
        const payload = {
            "guid": partyData.guid,
            "phonenumber": partyData.phoneNumber,
            "type": adParams.mfaType
        };

        setFormValues({ ...formValues, "mfaCode": "" });

        setLoading(true);
        Service.generateMfaCodeCall(payload)
        .then((response)=> {
            setLoading(false);
            if(response.code === Constants.SUCCESS_CODE_200){
                setPage(Constants.PAGE_MFA_VARIFY_2);
                context.setNotification({...context.notification, isVisible: false, title: "", message: "", type: "info", count: 0 })
            } else if(response.code === "9033"){
                setAdParams({...adParams, validateMfaError: false});
                setPage(Constants.PAGE_MFA_ERROR_7);
                context.setNotification({...context.notification, isVisible: false, title: "", message: "", type: "info", count: 0 })
            } else if(response.code === "9025"){
                setPage(Constants.PAGE_ERROR_5);
                context.setNotification({...context.notification, isVisible: false, title: "", message: "", type: "info", count: 0 })
            } else{
                // Error codes 9022, 9052, 2002
                let count = context.notification.count ? context.notification.count+1 : 1;
                if(count === 3){
                    setPage(Constants.PAGE_ERROR_5);
                    context.setNotification({...context.notification, isVisible: false, title: "", message: "", type: "info", count: 0 })
                } else {
                    let title = Codes.title.something_went_wrong;
                    let message = Codes.message.something_went_wrong;
                    context.setNotification({...context.notification, isVisible: true, title: title, message: message, type: "info", count: count })
                }
            }
        });
    }

    function validate(){

        let isValid = true;
        
        if(isEmpty(formValues.mfaCode)){
            let formError = {id: 'mfaCode', errMsg: getErrorMessage("mfaCode"), isError: true };
            setFormErrors({...formErrors, mfaCode: formError})
            isValid = false;
        }
        return isValid;
    }

    function varifyMfaCode(){

        const payload = {
            "guid": partyData.guid,
            "phonenumber": partyData.phoneNumber+"",
            "code": formValues.mfaCode?.trim()
        };

        setLoading(true);
        Service.validateMfaCodeCall(payload)
        .then((response)=> {
            setLoading(false);
            if(response.code === Constants.SUCCESS_CODE_200){
                setPage(Constants.PAGE_FRESH_FLOW_PAGE_3);
                context.setNotification({...context.notification, isVisible: false, title: "", message: "", type: "info", count: 0 })
            } else if(response.code === "9000"){
                setAdParams({...adParams, validateMfaError: true});
                setPage(Constants.PAGE_MFA_ERROR_7);
                context.setNotification({...context.notification, isVisible: false, title: "", message: "", type: "info", count: 0 })
            } else if(response.code === "9004"){
                let formError = {id: 'mfaCode', errMsg: getErrorMessage("invalidMfaCode"), isError: true };
                setFormErrors({...formErrors, mfaCode: formError})
                setFormValues({...formValues, mfaCode: ""});
                context.setNotification({...context.notification, isVisible: false, title: "", message: "", type: "info", count: 0 })
            } else{
                // Error codes 9015, 9023, 9024, 9052, 
                let count = context.notification.count ? context.notification.count+1 : 1;
                if(count >= 3){
                    setPage(Constants.PAGE_ERROR_5);
                    context.setNotification({...context.notification, isVisible: false, title: "", message: "", type: "info", count: 0 })
                } else {
                    let title = Codes.title.something_went_wrong;
                    let message = Codes.message.something_went_wrong;
                    context.setNotification({...context.notification, isVisible: true, title: title, message: message, type: "info", count: count })
                }
            }
        });
    }
    
    return (
        <Grid data-testid="mfaVerification-root" container md={12} xs={12} spacing={0}>   
        
            <Grid item md={12} xs={12} className="mfaVerification-gridSeparation">
                <div className="mfaVerification-header-title">Authorization Code</div>   
            </Grid>
            <Grid item md={12} xs={12} className="mfaVerification-gridSeparation">
                <div className="mfaVerification-header-subtitle">Please enter the code that was sent to your text.</div>      
            </Grid>
            <Grid item md={12} xs={12} className={"mfaVerification-gridSeparation mfaVerification-input-item myinput"+(formErrors.mfaCode.isError ? " error" : "")}>
                <label data-testid="mfaVerification-mfaCodeLabel" className="mfaVerification-labelStyle">
                    Enter your one time code
                </label>
                <TextInput variant={INPUT_VARIANT.BASIC_LINE}
                    id='mfaCode'
                    data-testid="mfaVerification-mfaCode"
                    placeholder="Code"
                    maxLength={50}
                    value={formValues.mfaCode}
                    errors={[formErrors.mfaCode]}
                    onChange={e => changeHandler("mfaCode", e)}
                    onKeyPress={(e)=> {
                        if(e.key === "Enter") submit();
                    }}
                    callouts={true}
                    customStyle={{
                        rootStyle: {
                            width: "50%",
                        },
                        inputStyle: {
                            padding: '0px'

                        }
                    }} name="mfaCode"
                />
                {(formErrors.mfaCode.isError) && <InputError inputId="mfaCode" formErrors={formErrors} /> }
            </Grid>

            <Grid item md={12} xs={12} className="mfaVerification-progress-button-container mfaVerification-gridSeparation">
                <Button
                    name="submit"
                    data-testid="mfaVerification-submitButton"
                    customStyle={{
                        buttonStyle: {
                            width: "150px",
                            height: "60px",
                            minWidth: "0px",
                            borderRadius: "0px"
                        }
                    }}
                    id="submitButton"
                    onClick={e => submit()}
                    disabled={false}>Continue</Button>
                {isLoading && <div className="progress-container">
                            <Progress                             
                                data-testid="mfaVerification-loader"
                                isFloating={false} customStyle={{
                                spinnerStyle: {
                                    position: "relative",
                                    height: "40px",
                                    width: "40px"
                                }
                            }} />
                            <p className="loading-text">Please wait while we process your information</p>
                        </div>
                }
            </Grid>

            <Grid item md={12} xs={12} className="mfaVerification-gridSeparation">
                <div onClick={()=> resendMfaCode()} className='resend-text'>Resend code</div>
            </Grid>

        </Grid>
    )
}
export default MfaVerificationComponent;