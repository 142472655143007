import React from 'react'
import Icon from '@mui/material/Icon';
import { Progress } from '@manulife/mux';
import Grid from '@mui/material/Grid';
import { hasNumbers, hasUpper, hasLower, hasSpecial, noRepeat, hasEight } from '../Utilities/ValidatePassword';
import "../style/PasswordStrengthValidator.css"
import InputError from './InputError';


const PasswordStrengthItem = ({ pwd, text, assertion, isPasswordStrengthPassed }) => {
    const isValid = assertion(pwd);
    return (
        <li>
            {
            (isPasswordStrengthPassed == undefined && isValid) || (isPasswordStrengthPassed)
                ? <Icon sx={{ color: "#282B3E",  fontSize: 20  }}>check</Icon>
                : <Icon sx={{ color: "#282B3E",  fontSize: 20  }}>clear</Icon>
            }
            <span className="passwordValidityTextStylesGrey">
                {text}
            </span>
        </li>
    );
}


const PasswordStrengthValidator = props => {
    
    const { pwd, onPasswordCheck, formErrors, isCheckingPassStrength, passwordValidated, isPasswordStrengthPassed } = props;

    return (
        <div className="root">
            <Grid item md={12} xs={12} className="strengthContainer">
                    {
                        (!isCheckingPassStrength && passwordValidated && isPasswordStrengthPassed) &&
                            <span onClick={onPasswordCheck} className="strengthCheckPassed">Password strength check passed!</span>
                    }
                    {
                        isCheckingPassStrength ?
                            <span onClick={onPasswordCheck} className="strengthCheckingText">Password strength - Checking...</span>
                            :
                            !(passwordValidated && isPasswordStrengthPassed) && (formErrors?.pwd?.isError && formErrors?.pwd?.errMsg) ?                            
                                <InputError inputId="pwd" formErrors={formErrors} />
                            
                            : !(passwordValidated && isPasswordStrengthPassed) &&
                            <span onClick={onPasswordCheck} className="strengthText">Check password strength</span>
                    }
                    {
                        isCheckingPassStrength &&
                        <div className="inlineDisplay">
                            <div className="inlineDisplay2">
                                <Progress
                                    size={30}
                                    isFloating={false}
                                    color="#0000C1"
                                    customStyle={{
                                        spinnerStyle: {
                                            position: "relative",
                                            height: "30px",
                                            width: "30px",
                                            marginTop: "10px"
                                        }
                                    }}/>
                            </div>
                        </div>
                    }
            </Grid>
            <div className="listRoot">
                <ul>
                    <PasswordStrengthItem
                        pwd={pwd}
                        text="Eight characters minimum"
                        assertion={(pwd)=> !hasEight(pwd)}
                        />
                    <PasswordStrengthItem
                        pwd={pwd}
                        text="One number"
                        assertion={(pwd)=> !hasNumbers(pwd)}
                         />
                    <PasswordStrengthItem
                        pwd={pwd}
                        text="One special character"
                        assertion={(pwd)=> !hasSpecial(pwd)}
                         />
                </ul>
                <ul>
                    <PasswordStrengthItem
                        pwd={pwd}
                        text="One lowercase letter"
                        assertion={(pwd)=> !hasLower(pwd)}
                         />
                    <PasswordStrengthItem
                        pwd={pwd}
                        text="One uppercase letter"
                        assertion={(pwd)=> !hasUpper(pwd)}
                         />
                    <PasswordStrengthItem
                        pwd={pwd}
                        text="No blocked words or sequences"
                        assertion={(pwd)=> !noRepeat(pwd)}
                        isPasswordStrengthPassed={isPasswordStrengthPassed}
                         />
                </ul>
            </div>
        </div>
    );
}

export default PasswordStrengthValidator