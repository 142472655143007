// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.top-container{
    width: 100%;
  }
  
  .bottom-container{
    display: flex;
    position: relative;
    width: 100%;
  }
  
  .body-container{
    width: calc(100% - 80px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  `, "",{"version":3,"sources":["webpack://./src/style/RouterComponentStyle.css"],"names":[],"mappings":";AACA;IACI,WAAW;EACb;;EAEA;IACE,aAAa;IACb,kBAAkB;IAClB,WAAW;EACb;;EAEA;IACE,wBAAwB;IACxB,aAAa;IACb,sBAAsB;IACtB,8BAA8B;EAChC","sourcesContent":["\r\n.top-container{\r\n    width: 100%;\r\n  }\r\n  \r\n  .bottom-container{\r\n    display: flex;\r\n    position: relative;\r\n    width: 100%;\r\n  }\r\n  \r\n  .body-container{\r\n    width: calc(100% - 80px);\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: space-between;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
