import React from 'react'
import "../style/FooterComponentStyle.css"
import Grid from '@mui/material/Grid';
import {ReactComponent as ManulifeLogo} from '../Resources/Images/manulife_logo.svg';
import {ReactComponent as ManulifeTextLogo} from '../Resources/Images/manulife_text_logo.svg';
import Constants from '../Utilities/Constants';



const Footer = (props) => {

    return (
        <Grid container md={12} xs={12}  className="footer-root">
            <ul className='footer-menu-container'>
                <li className='footer-menu-item'><a href={Constants.URL_ACCESSIBILITY_PAGE} className='footer-link' target="_blank">Accessibility</a></li>
                <li className='footer-menu-item'><a href={Constants.URL_LEGAL_PAGE} className='footer-link' target="_blank">Legal</a></li>
                <li className='footer-menu-item'><a href={Constants.URL_PRIVACY_PAGE} className='footer-link' target="_blank">Privacy & Security</a></li>
            </ul>
            <Grid className='footer-logo-copyright-container'>
                <div className='footer-logo-container'>
                    <ManulifeLogo className='manulife-logo'/>
                    <ManulifeTextLogo className='manulife-text-logo'/>
                </div>

                <div className='footer-copyright-content-container'>
                    <span className='footer-copyright-content'>
                    © The Manufacturers Life Insurance Company 1999 – 2023 <br/>
                    For Distributor / Financial Representative use only. Not for use with the public.<br/>
                    Products are issued by The Manufacturers Life Insurance Company (Bermuda Branch).<br/>
                    Guaranteed product features are dependent upon minimum premium requirements and the claims-paying ability of the issuer.<br/>
                    Manulife and the Block Design are trademarks of The Manufacturers Life Insurance Company and are used by it, and by its affiliates under license.
                    </span>
                </div>
            </Grid>
        </Grid>
    );
}

export default Footer;
