import { Button, Progress } from '@manulife/mux';
import { Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import "../style/CongratulationComponentStyle.css"
import Service from '../Services/Service';
import { MyContext } from '../Context/MyContext';
import Constants from '../Utilities/Constants';
import Codes from '../Resources/Config/Codes.json';
import { getQueryParams } from '../Utilities/Utility';


const CongratulationComponent = (props) => {
    
    let params = getQueryParams();
    let {adParams} = props;
    const [isLoading, setLoading] = useState(false);
    const context = useContext(MyContext);

    useEffect(()=> {
        context.setSessionTimeoutEnable(true);
    }, [])

    function goToMyAccount(){
        
        let payload = {
            "username": adParams.userName
        };

        setLoading(true);
        Service.ssoLogin(payload)
        .then((response)=> {
            setLoading(false);
            if(response.code === Constants.SUCCESS_CODE_2001){
                 const token = response.details.token;
                let redirectUrl = response.details.postUrl;
                   if (token != null) {
                    console.log("call post")
                    console.log("token", token);
                    console.log("Post Url", redirectUrl);
                    if(redirectUrl.endsWith("jwt=")){
                        redirectUrl = redirectUrl+token;
                    }
                    console.log("redirectUrl=", redirectUrl);
                    autoLoginForm(redirectUrl, token, params.appId);
                }
            } else{
                let title = Codes.title.something_went_wrong;
                let message = Codes.message.something_went_wrong;
                context.setNotification({...context.notification, isVisible: true, title: title, message: message, type: "info"})
                console.log("Error=", response);
            }
        })
    }

    
    function autoLoginForm(url, jwtToken, applicationId) {
        let form = document.createElement("form");
        let jwt = document.createElement("input");
        let appId = document.createElement("input");

        form.method = "POST";
        form.action = url;

        jwt.type = "hidden";
        jwt.value = jwtToken;
        jwt.id = "jwt";
        form.appendChild(jwt);

        appId.type = "hidden";
        appId.value = applicationId;
        appId.id = "appId";
        form.appendChild(appId);

        document.body.appendChild(form);
        form.submit();
    }

    return (
        <div data-testid="Congratulations-root" className="congratulations-root">
            <Grid item md={12} xs={12} className="congratulations-gridSeparation">
            <div className="congratulations-success">Registration successful</div>
            <div className="congratulations-content">Congratulations!
                <br/>
                <br/>
                Your registration is successful.
                <br/>
                <br/>
                You will now be able to access and policy documents for your Manulife Bermuda policy via our website. You will also receive email alerts when a new document has been posted.
                <br/>
                <br/>
                If you need any further assistance please contact your advisor.
            </div>
            </Grid>

            <Grid item md={12} xs={12} className="congratulations-progress-button-container congratulations-gridSeparation">
                    <Button
                        name="submit"
                        data-testid="congratulations-myAccountButton"
                        customStyle={{
                            buttonStyle: {
                                height: "60px",
                                minWidth: "0px",
                                borderRadius: "0px",
                                paddingLeft: "30px",
                                paddingRight: "30px"
                            }
                        }}
                        id="submitButton"
                        onClick={e => goToMyAccount()}
                        disabled={false}>My Account
                    </Button>
                    {isLoading && <div className="congratulations-progress-container">
                                <Progress                             
                                    data-testid="congratulations-loader"
                                    isFloating={false} customStyle={{
                                    spinnerStyle: {
                                        position: "relative",
                                        height: "40px",
                                        width: "40px"
                                    }
                                }} />
                                <p className="congratulations-loading-text">Please wait while we process your information</p>
                            </div>
                    }
                </Grid>
        </div>
    );
}

export default CongratulationComponent;
