
const EXTRACT_JWT_API = "/extractJwt";
const GENERATE_MFA_CODE_API = "/generateMFA";
const VALIDATE_MFA_CODE_API = "/validateMFA";
const CREATE_USER_API = "/createuser";
const VALIDATE_USERNAME_API = "/validateusername";
const VALIDATE_PASSWORD_API = "/validatepassword";
const SSO_LOGIN_API = "/ssologin";

const URLConstants = {
    EXTRACT_JWT_API,
    GENERATE_MFA_CODE_API,
    VALIDATE_MFA_CODE_API,
    CREATE_USER_API,
    VALIDATE_USERNAME_API,
    VALIDATE_PASSWORD_API,
    SSO_LOGIN_API
};

export default URLConstants;