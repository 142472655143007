import React, { useContext } from 'react';
import URLConstants from '../Utilities/URLConstants';
import axios from 'axios';
import * as Constants from '../Utilities/Constants';
import { MyContext } from '../Context/MyContext';
import { getQueryParams } from '../Utilities/Utility';
let params = getQueryParams();



const BACKEND_URL = process.env.REACT_APP_BACKEND ? `${process.env.REACT_APP_BACKEND}` : "http://localhost:8080";
const isLocalhost = window.location.href.includes('localhost');

const sendRequest = (endpoint, method, payload) => {
    let _headers = {}
    
    if(payload){
        payload["appid"] = params.appId;
        _headers["Content-Type"] = "application/json";
    }

    const serviceUrl = BACKEND_URL+endpoint;
    const options = {
        url: serviceUrl,
        method: method || 'GET',
        headers: _headers,
        mode: isLocalhost ? 'cors' : 'same-origin',
        data: typeof payload === 'string' ? payload : JSON.stringify(payload)
    }
    return call(options);
}

async function call(options){

    
    return new Promise((resolve, reject)=> {
        axios(options)
            .then(response => {  
                
                console.log("0. then:options==", options); 
                console.log("1. then:response==", response); 
                let status = response.status;                
                if(status < Constants.ERROR_CODE){
                    return response.data;
                } else{
                    return {"code": status, "message": "Something went wrong, api failed to return response"};
                }    
            }).then(res=> {
                console.log("2. then:res==", res); 
                resolve(res);
            }).catch(e => {
                console.log("3. then:e==", e); 
                resolve({"code": 500, "message": "Something went wrong, cought unknown exception"});
            });
    });
}

const Service = {
    extractJWTCall: (payload)=> sendRequest(URLConstants.EXTRACT_JWT_API, "POST", payload),
    generateMfaCodeCall: (payload)=> sendRequest(URLConstants.GENERATE_MFA_CODE_API, "POST", payload),
    validateMfaCodeCall: (payload)=> sendRequest(URLConstants.VALIDATE_MFA_CODE_API, "POST", payload),
    validateUsername: (payload)=> sendRequest(URLConstants.VALIDATE_USERNAME_API, "POST", payload),
    createUserCall: (payload)=> sendRequest(URLConstants.CREATE_USER_API, "POST", payload),
    validatePassword: (payload)=> sendRequest(URLConstants.VALIDATE_PASSWORD_API, "POST", payload),
    ssoLogin: (payload)=> sendRequest(URLConstants.SSO_LOGIN_API, "POST", payload)
}

export default Service;