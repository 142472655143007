import { Button } from '@manulife/mux';
import { Grid } from '@mui/material';
import React, { useContext, useEffect } from 'react'
import "../style/SessionExpiredComponentStyle.css"
import { MyContext } from '../Context/MyContext';


const SessionExpiredComponent = (props) => {

    
    const context = useContext(MyContext);

    useEffect(()=> {
        context.setSessionTimeoutEnable(false);
    }, [])

    function backToRegistration(){
        window.location.reload();
    }

    return (
        <div data-testid="session-root" className="session-root">
            <Grid item md={12} xs={12} className="session-gridSeparation">
            <div className="session-success">Your session has expired.</div>
            <div className="session-content">For your security, we haven’t saved any of your personal data. Please try registering again. </div>
            </Grid>

            <Grid item md={12} xs={12} className="session-progress-button-container session-gridSeparation">
                    <Button
                        name="submit"
                        data-testid="session-submitButton"
                        customStyle={{
                            buttonStyle: {
                                height: "60px",
                                minWidth: "0px",
                                borderRadius: "0px",
                                paddingLeft: "30px",
                                paddingRight: "30px",
                                fontWeight: "600",
                                fontSize: "18px"
                            }
                        }}
                        id="submitButton"
                        onClick={e => backToRegistration()}
                        disabled={false}>Back to Registration Page
                    </Button>
                </Grid>
        </div>
    );
}

export default SessionExpiredComponent;
