import React from 'react'
import "../style/LoaderComponentStyle.css"
import {Progress} from '@manulife/mux';


const LoaderComponent = (props) => {

    return (
        <div className="loader-root">
            <div className="progress-container">
                <Progress                             
                    data-testid="mfaType-loader"
                    isFloating={false} 
                    customStyle={{
                    spinnerStyle: {
                        position: "relative",
                        height: "40px",
                        width: "40px"
                    }
                }} />
                <p className="loading-text">Please wait while we process your information</p>
            </div>
        </div>
    );
}

export default LoaderComponent;
