import React from 'react';
import "../style/RouterComponentStyle.css"
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Footer from './FooterComponent';
import Header from './HeaderComponent';
import LeftComponent from './LeftComponent';
import MainComponent from './MainComponent';

export default function RouterComponent(){

    return <Router basename={process.env.REACT_APP_BASENAME}>
            <div className="top-container">
              <Header/>
            </div>
            <div className="bottom-container">
              <LeftComponent/>
              <div className='body-container'>
                <Routes>
                  <Route path="/" element={<MainComponent/>}/>
                  <Route path="/*" element={<Navigate to="/" replace/>}/>
                </Routes>
                <Footer/>
              </div>
            </div>
          </Router>
}