export const APP_ID = "27096464";

// Codes
export const ERROR_CODE = 300;
export const SUCCESS_CODE_200 = "200";
export const SUCCESS_CODE_0000 = "0000";
export const SUCCESS_CODE_1000 = "1000";
export const SUCCESS_CODE_0001 = "0001";
export const SUCCESS_CODE_1001 = "1001";
export const SUCCESS_CODE_2001 = "2001";
export const INVALIDE_TOKEN_2000 = "2000";


//Pages
export const PAGE_MFA_TYPE_1 = 1;
export const PAGE_MFA_VARIFY_2 = 2;
export const PAGE_FRESH_FLOW_PAGE_3 = 3;
export const PAGE_CONGRATULATION_PAGE_4 = 4;
export const PAGE_ERROR_5 = 5;
export const PAGE_SESSION_EXPIRED_6 = 6;
export const PAGE_MFA_ERROR_7 = 7;
export const PAGE_MAINTENANCE_ERROR_8 = 8;
export const PAGE_LINK_EXPIRED_9 = 9;

// URLs
export const URL_MLB_HOME_PAGE = "https://www.manulifebermuda.com/";
export const URL_CONSENT_DELEIEVRY_PAGE = "https://client.manulifeghnw.com/ghnwclientportal/s/electronic-delivery";
export const URL_ACCESSIBILITY_PAGE = "https://client.manulifeghnw.com/ghnwclientportal/s/accessibility";
export const URL_LEGAL_PAGE = "https://client.manulifeghnw.com/ghnwclientportal/s/legal";
export const URL_PRIVACY_PAGE = "https://client.manulifeghnw.com/ghnwclientportal/s/privacy";


const Constants = {
    APP_ID,
    ERROR_CODE,
    SUCCESS_CODE_200,
    SUCCESS_CODE_0000,
    SUCCESS_CODE_0001,
    SUCCESS_CODE_1000,
    SUCCESS_CODE_1001,
    SUCCESS_CODE_2001,
    INVALIDE_TOKEN_2000,
    PAGE_MFA_TYPE_1,
    PAGE_MFA_VARIFY_2,
    PAGE_FRESH_FLOW_PAGE_3,
    PAGE_CONGRATULATION_PAGE_4,
    PAGE_ERROR_5,
    PAGE_SESSION_EXPIRED_6,
    PAGE_MFA_ERROR_7,
    PAGE_MAINTENANCE_ERROR_8,
    PAGE_LINK_EXPIRED_9,

    URL_MLB_HOME_PAGE,
    URL_CONSENT_DELEIEVRY_PAGE,
    URL_ACCESSIBILITY_PAGE,
    URL_LEGAL_PAGE,
    URL_PRIVACY_PAGE
};

export default Constants;