import React from 'react'
import "../style/InputError.css"
import { Grid } from '@mui/material';
import { ReactComponent as DarkRedWarning } from '../Resources/Images/dark_red_warning_ic.svg';


const InputError = (props) => {

    let {formErrors, inputId} = props;
    return (
            <Grid item md={12} xs={12} className="errorContainer">
                <DarkRedWarning/>
                <span className="errorText">
                    {formErrors[inputId].errMsg}
                </span>
            </Grid>
    );
}

export default InputError;
