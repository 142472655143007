import React from 'react'
import "../style/HeaderComponentStyle.css"
import { ReactComponent as ManulifeLogo } from '../Resources/Images/manulife_header_logo.svg';
import { ReactComponent as ManulifeIcon } from '../Resources/Images/manulife_icon.svg';


const Header = (props) => {

    return (
        <div className="header-root">
            <div className='container'>
                <div className='header-left-logo'>
                    <div className='manulife-icon-container'>
                        <ManulifeIcon/>
                    </div>
                    <ManulifeLogo />
                </div>
                {/* <button
                    className="button-container"
                    type="button"
                    aria-controls="step-list"
                    aria-expanded={false}>
                        <span className='button-text'>Log out</span>
                </button> */}
            </div>
        </div>
    );
}

export default Header;
