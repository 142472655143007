import React, { useState } from 'react';

import './App.css';
import { ThemeProvider } from 'styled-components';
import { THEMES } from '@manulife/mux';
import { MyContext } from './Context/MyContext';
import RouterComponent from './Components/RouterComponent';


function App(props) {

  const [notification, setNotification] = useState({
    isVisible: false,
    type: "",
    title: "",
    message: ""
  });

  const [sessionTimeoutEnable, setSessionTimeoutEnable] = useState(true);

  
  function iniFrame() {
    if (window.self !== window.top) {
      console.log("In iFrame");
      return true;
    } else {
      console.log("Not in iFrame");
      return false;
    }
  }
  if (iniFrame()) {
    return null
  }
  
  
  return (
    <div data-testid="app" className="App">      
    <ThemeProvider theme={THEMES.johnhancockTheme}>
      <MyContext.Provider value={{notification, setNotification, sessionTimeoutEnable, setSessionTimeoutEnable}}>
        <div className="root">
          <RouterComponent />
        </div>        
      </MyContext.Provider>
    </ThemeProvider>
    </div>
  );
}

export default App;
