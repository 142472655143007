// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainComponent-root {
    width: 100%;
    padding-left: 70px;
    margin-top: 34px;
    max-width: 75%;
    padding-bottom: 224px;
}


@media screen and (max-width: 600px) {
    .MainComponent-root {        
        padding-left: 30px;
    }
}

.gridSeparation {
    padding-top: 24px;
}

.labelStyle {
    font-weight: 400;
    font-family: Manulife JH Sans;
    font-size: 14px;
    color: #5E6073;
    line-height: 20px;
}
.textStyle {
    font-family: Manulife JH Sans;
    font-size: "18px";
    font-weight: "500";
    line-height: "22px";
    color: #000000;
}
.confirmTextStyle {
    font-family: Manulife JH Sans;
    font-size: "18px";
    font-weight: "500";
    line-height: "22px" l
}`, "",{"version":3,"sources":["webpack://./src/style/MainComponentStyle.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;IAClB,gBAAgB;IAChB,cAAc;IACd,qBAAqB;AACzB;;;AAGA;IACI;QACI,kBAAkB;IACtB;AACJ;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,6BAA6B;IAC7B,eAAe;IACf,cAAc;IACd,iBAAiB;AACrB;AACA;IACI,6BAA6B;IAC7B,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;IACnB,cAAc;AAClB;AACA;IACI,6BAA6B;IAC7B,iBAAiB;IACjB,kBAAkB;IAClB;AACJ","sourcesContent":[".MainComponent-root {\r\n    width: 100%;\r\n    padding-left: 70px;\r\n    margin-top: 34px;\r\n    max-width: 75%;\r\n    padding-bottom: 224px;\r\n}\r\n\r\n\r\n@media screen and (max-width: 600px) {\r\n    .MainComponent-root {        \r\n        padding-left: 30px;\r\n    }\r\n}\r\n\r\n.gridSeparation {\r\n    padding-top: 24px;\r\n}\r\n\r\n.labelStyle {\r\n    font-weight: 400;\r\n    font-family: Manulife JH Sans;\r\n    font-size: 14px;\r\n    color: #5E6073;\r\n    line-height: 20px;\r\n}\r\n.textStyle {\r\n    font-family: Manulife JH Sans;\r\n    font-size: \"18px\";\r\n    font-weight: \"500\";\r\n    line-height: \"22px\";\r\n    color: #000000;\r\n}\r\n.confirmTextStyle {\r\n    font-family: Manulife JH Sans;\r\n    font-size: \"18px\";\r\n    font-weight: \"500\";\r\n    line-height: \"22px\" l\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
