import { Grid } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { MyContext } from '../Context/MyContext';


const MaintenancePage = props => {

    
    const context = useContext(MyContext);

    useEffect(()=> {
        context.setSessionTimeoutEnable(false);
    }, [])
    
    let { adParams } = props;
    return (
        <div data-testid="Maintenance-root">
            <Grid container xs={12} className="gridSeparation">
                <Grid item md={8} xs={12}>
                    {
                        adParams.maintenanceMessage === 'Maintenance' ? <span className="errorHeaderStyles">
                                We are currently down for scheduled maintenance.
                            </span> : <span className="errorHeaderStyles">
                                We are currently experiencing technical issues.
                            </span>
                    }
                </Grid>
            </Grid>
            <Grid container xs={12} className="gridSeparation">
                <Grid item md={8} xs={12}>
                    {
                        adParams.maintenanceMessage === 'Maintenance' ? <span className="subheaderStyles">
                                We are working as quickly as possible to restore our services and apologize for any inconvenience.
                            </span> : <span className="subheaderStyles">
                                But we are working as quickly as possible to restore our services and apologize for any inconvenience.
                            </span>
                    }
                </Grid>
            </Grid>
            
        </div>
    );
};

export default MaintenancePage;