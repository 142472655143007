// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mfa-error-root{
    width:100%;
    padding-top:25px
}
.mfa-error-subheaderStyles{
    font-weight:300;
    font-family: Manulife JH Sans;
    font-size:18px;
    line-height:34px;
    font-style:normal;
    color:#282B3E
}
.mfa-error-errorHeaderStyles{
    font-weight:500;
    font-family: Manulife JH Sans;
    font-size:48px;
    line-height:58px;
    font-style:normal;
    color:#282B3E
}
.mfa-error-buttonTextStyles{
    font-weight:500;
    font-family: Manulife JH Sans;
    font-size:17px;
    line-height:22px;
    font-style:normal;
    text-align:center;
    color:#FFFFFF
}
.mfa-error-gridSeparation{
    padding-bottom:50px
}
.mfa-error-buttonPadding{
    padding-top:25px;
    padding-bottom:120px
}`, "",{"version":3,"sources":["webpack://./src/style/MfaErrorPage.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV;AACJ;AACA;IACI,eAAe;IACf,6BAA6B;IAC7B,cAAc;IACd,gBAAgB;IAChB,iBAAiB;IACjB;AACJ;AACA;IACI,eAAe;IACf,6BAA6B;IAC7B,cAAc;IACd,gBAAgB;IAChB,iBAAiB;IACjB;AACJ;AACA;IACI,eAAe;IACf,6BAA6B;IAC7B,cAAc;IACd,gBAAgB;IAChB,iBAAiB;IACjB,iBAAiB;IACjB;AACJ;AACA;IACI;AACJ;AACA;IACI,gBAAgB;IAChB;AACJ","sourcesContent":[".mfa-error-root{\r\n    width:100%;\r\n    padding-top:25px\r\n}\r\n.mfa-error-subheaderStyles{\r\n    font-weight:300;\r\n    font-family: Manulife JH Sans;\r\n    font-size:18px;\r\n    line-height:34px;\r\n    font-style:normal;\r\n    color:#282B3E\r\n}\r\n.mfa-error-errorHeaderStyles{\r\n    font-weight:500;\r\n    font-family: Manulife JH Sans;\r\n    font-size:48px;\r\n    line-height:58px;\r\n    font-style:normal;\r\n    color:#282B3E\r\n}\r\n.mfa-error-buttonTextStyles{\r\n    font-weight:500;\r\n    font-family: Manulife JH Sans;\r\n    font-size:17px;\r\n    line-height:22px;\r\n    font-style:normal;\r\n    text-align:center;\r\n    color:#FFFFFF\r\n}\r\n.mfa-error-gridSeparation{\r\n    padding-bottom:50px\r\n}\r\n.mfa-error-buttonPadding{\r\n    padding-top:25px;\r\n    padding-bottom:120px\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
