
export function isBlank(item){
    return item === ''
}

export function isNull(item){
    return item == null
}

export function camelize(str) {
    if(isNull(str)) return "";

    return str.split(" ").map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }).join(" ");
  }

export function getQueryParams () {
  const search = new URLSearchParams(window.location.search);
  return {
    jwt: search.get('jwt') || null,
    appId: search.get('appId') || null
  };
};


export function redirectTo (redirectUrl, expectedUrls) {
  let isValidUrl = false;
  for (let i = 0; i < expectedUrls.length; i++) {
    const expectedUrl = expectedUrls[i];
    if(redirectUrl.startsWith(expectedUrl)){
      isValidUrl = true;
      break;
    }
  }
  if(isValidUrl){
    window.open(redirectUrl, "_self", "noreferrer", "noopener");
  }
};


export function doNothing(){
    // This function is used to prevent input field crash on keypress.
}

const Utility = {
 isBlank, isNull, camelize, getQueryParams, redirectTo, doNothing
}

export default Utility;