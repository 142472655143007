import React, { useContext, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { Button } from '@manulife/mux';
import "../style/ErrorPage.css";
import Constants from '../Utilities/Constants';
import Utility from '../Utilities/Utility';
import { MyContext } from '../Context/MyContext';


const ErrorPage = (props) => {

    const context = useContext(MyContext);

    useEffect(() => {
        window.scrollTo(0, 0);
        context.setSessionTimeoutEnable(false);
    }, []);

    const redirectToHome = () => {
        Utility.redirectTo(Constants.URL_MLB_HOME_PAGE, [Constants.URL_MLB_HOME_PAGE])
    };

    return (
        <div  data-testid="ErrorPage-root" className={"root"}>
            <Grid container md={8} xs={12} className={"gridSeparation"}>
                <span className={"errorHeaderStyles"}>We’re having trouble setting up your account online.</span>
            </Grid>
            <Grid container md={8} xs={12} className={"gridSeparation"}>
                <span className={"subheaderStyles"}>Please contact your advisor for assistance.</span>
            </Grid>
            
            <Grid container xs={12} className={"buttonPadding"}>
                <Grid item md={8} xs={12}>
                    <Button
                        customStyle={{
                            buttonStyle: {
                                width: "300px",
                                height: "70px"
                            }
                        }}
                        name="ReturnToJHButton"
                        id="ReturnToJHButton"
                        onClick={e => redirectToHome()}><div className={"buttonTextStyles"}>Go to Manulife Bermuda</div></Button>
                </Grid>
            </Grid>
        </div>
    );
};

export default ErrorPage
