// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("Resources/Fonts/ManulifeJHSans-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("Resources/Fonts/ManulifeJHSansOptimized.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --text-primary: #600;
  --screen-sm-min:   768px;
  --screen-md-min:   992px;
  --screen-lg-min:   1200px;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: 'Manulife JH Sans';
  src: local('Manulife JH Sans'), url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('TrueType');
}


@font-face {
  font-family: 'Manulife JH Sans Optimized';
  src: local('Manulife JH Sans Optimized'), url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('TrueType');
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,wBAAwB;EACxB,wBAAwB;EACxB,yBAAyB;AAC3B;AACA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;;AAGA;EACE,+BAA+B;EAC/B,0FAAkG;AACpG;;;AAGA;EACE,yCAAyC;EACzC,oGAA6G;AAC/G","sourcesContent":[":root {\r\n  --text-primary: #600;\r\n  --screen-sm-min:   768px;\r\n  --screen-md-min:   992px;\r\n  --screen-lg-min:   1200px;\r\n}\r\nbody {\r\n  margin: 0;\r\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\r\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\r\n    sans-serif;\r\n  -webkit-font-smoothing: antialiased;\r\n  -moz-osx-font-smoothing: grayscale;\r\n}\r\n\r\ncode {\r\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\r\n    monospace;\r\n}\r\n\r\n\r\n@font-face {\r\n  font-family: 'Manulife JH Sans';\r\n  src: local('Manulife JH Sans'), url(Resources/Fonts/ManulifeJHSans-Regular.ttf) format('TrueType');\r\n}\r\n\r\n\r\n@font-face {\r\n  font-family: 'Manulife JH Sans Optimized';\r\n  src: local('Manulife JH Sans Optimized'), url(Resources/Fonts/ManulifeJHSansOptimized.ttf) format('TrueType');\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
