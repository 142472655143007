// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and(min-height: 450px) {
    .left-root{
        display: none;
    }
}
.left-root{
    background-color: #2F3242;
    width: 80px !important;
    min-height: calc(100vh - 94px);
}`, "",{"version":3,"sources":["webpack://./src/style/LeftComponentStyle.css"],"names":[],"mappings":"AAAA;IACI;QACI,aAAa;IACjB;AACJ;AACA;IACI,yBAAyB;IACzB,sBAAsB;IACtB,8BAA8B;AAClC","sourcesContent":["@media screen and(min-height: 450px) {\r\n    .left-root{\r\n        display: none;\r\n    }\r\n}\r\n.left-root{\r\n    background-color: #2F3242;\r\n    width: 80px !important;\r\n    min-height: calc(100vh - 94px);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
