import React, { useContext, useEffect } from 'react';
import "../style/MainComponentStyle.css"
import Grid from '@mui/material/Grid';
import { useState } from 'react';
import MyNotification from './MyNotificationComponent';
import MfaTypeComponent from './MfaTypeComponent';
import MfaVerificationComponent from './MfaVerificationComponent';
import Service from '../Services/Service';
import { getQueryParams } from '../Utilities/Utility';
import Codes from '../Resources/Config/Codes.json';
import { MyContext } from '../Context/MyContext';
import Constants from '../Utilities/Constants';
import LoaderComponent from './LoaderComponent';
import RegistrationComponent from './RegistrationComponent';
import CongratulationComponent from './CongratulationComponent';
import SessionExpiredComponent from './SessionExpiredComponent';
import ErrorPage from './ErrorPage';
import MfaErrorPage from './MfaErrorPage';
import MaintenancePage from './MaintenancePage';
import LinkExpiredComponent from './LinkExpiredComponent';
import { useIdleTimer } from 'react-idle-timer'


const MainComponent = () => {

    const context = useContext(MyContext);
    const [page, setPage] = useState(Constants.PAGE_MFA_TYPE_1);
    const [partyData, setPartyData] = useState(null);
    const [adParams, setAdParams] = useState({});
    const [isLoading, setLoading] = useState(true);
    const [mfaDisabled, setMfaDisabled] = useState(false);
    let params = getQueryParams();
    
    const handleOnIdle = e => {
        if(context.sessionTimeoutEnable){
            setPage(Constants.PAGE_SESSION_EXPIRED_6)
        }
    }

    // 15 min session timeout if in Idle mode
    const { getRemainingTime, getLastActiveTime } = useIdleTimer({
        timeout: 1000 * 60 * 15,
        onIdle: handleOnIdle,
        debounce: 500
    })

    useEffect(()=> {
        if(params?.jwt){
            extractJwtToken();
        } else{
            setLoading(false);
            setPage(Constants.PAGE_LINK_EXPIRED_9) // invalid or empty JWT 
        }
    }, []);
    
    function extractJwtToken(){

        const payload = {
            "token": params.jwt
        };

        Service.extractJWTCall(payload)
        .then((response)=> {
            setLoading(false);
            let successCodes = [Constants.SUCCESS_CODE_1001, Constants.SUCCESS_CODE_1000]
            let linkExpiredCodes = ["2000", "9005", "2001", "2002", "9001"];
            if(response.maintenancemessage === "Active"){
                let code = response.code;
                if(successCodes.includes(code)){
                    let party = response.details;
                    if(!party.requestid){
                        return setPage(Constants.PAGE_LINK_EXPIRED_9);
                    }
                    party["guid"] = party.requestid ? party.requestid : "";
                    party["phoneNumber"] = party.phoneNumber ? party.phoneNumber : "";
                    setPartyData(party);
                    if(code === Constants.SUCCESS_CODE_1000) {
                        setMfaDisabled(true);
                        return setPage(Constants.PAGE_FRESH_FLOW_PAGE_3);
                    }
                } else if(linkExpiredCodes.includes(code)){
                    setPage(Constants.PAGE_LINK_EXPIRED_9);
                } else {
                    // System Error Codes 9009, 9002
                    let title =  Codes.title.something_went_wrong;
                    let message = Codes.message.something_went_wrong;
                    context.setNotification({...context.notification, isVisible: true, title: title, message: message, type: "info"})
                }
            } else { // in case of system mantenance or system technical failure
                setAdParams({...adParams, maintenanceMessage: response.maintenancemessage})
                setPage(Constants.PAGE_MAINTENANCE_ERROR_8);
            }
        });
    }

    return (
        <div className='MainComponent-root'>
            <MyNotification />
            {
                isLoading && <LoaderComponent/>
            }
            <Grid xs={12} md={12} container data-testid="MainComponent-root" spacing={0}>
                <Grid item xs={12} md={12}>
                    {
                        page === Constants.PAGE_MFA_TYPE_1 && partyData &&
                        <MfaTypeComponent setPage={setPage} partyData={partyData} adParams={adParams} setAdParams={setAdParams}/>
                    }
                    {
                        page === Constants.PAGE_MFA_VARIFY_2 &&
                        <MfaVerificationComponent setPage={setPage} partyData={partyData} adParams={adParams} setAdParams={setAdParams}/>
                    }
                    {
                        page === Constants.PAGE_FRESH_FLOW_PAGE_3 &&
                        <RegistrationComponent mfaDisabled={mfaDisabled} setPage={setPage} partyData={partyData} adParams={adParams} setAdParams={setAdParams}/>
                    }
                    {
                        page === Constants.PAGE_CONGRATULATION_PAGE_4 &&
                        <CongratulationComponent setPage={setPage} adParams={adParams} setAdParams={setAdParams}/>
                    }
                    {
                        page === Constants.PAGE_ERROR_5 &&
                        <ErrorPage setPage={setPage}/>
                    }
                    {
                        page === Constants.PAGE_MAINTENANCE_ERROR_8 &&
                        <MaintenancePage setPage={setPage} adParams={adParams} setAdParams={setAdParams}/>
                    }
                    {
                        page === Constants.PAGE_SESSION_EXPIRED_6 &&
                        <SessionExpiredComponent setPage={setPage}/>
                    }
                    {
                        page === Constants.PAGE_LINK_EXPIRED_9 &&
                        <LinkExpiredComponent setPage={setPage}/>
                    }
                    {
                        page === Constants.PAGE_MFA_ERROR_7 &&
                        <MfaErrorPage setPage={setPage} adParams={adParams} setAdParams={setAdParams}/>
                    }
                    
                </Grid>
            </Grid>
        </div>
    )
}

export default MainComponent;