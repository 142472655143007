import React, { useContext, useLayoutEffect, useRef, useState } from 'react'
import "../style/MyNotificationComponentStyle.css"
import { ReactComponent as Warning } from '../Resources/Images/warning_ic.svg';
import { ReactComponent as Cross } from '../Resources/Images/cross_ic.svg';
import { MyContext } from '../Context/MyContext';

const MyNotification = (props) => {

    const rootRef = useRef(null);
    const context = useContext(MyContext);
    let [notificationHeight, setNotificationHeght] = useState(84);

    useLayoutEffect(()=> {
        setNotificationHeght(rootRef.current ? rootRef.current.offsetHeight : 84);
    }, [context.notification.isVisible])
    return (
        <MyContext.Consumer>
             {({notification, setNotification}) => {
                return notification.isVisible ? (
                    <div ref={rootRef} className="mynotification-root">
                        <div style={{height: notificationHeight}} className='notification-color'/>
                        <Warning />
                        <div className='notification-right-container'>
                            <div className='notification-body'>
                                <span className='message-title'>{notification.title}</span>
                                <span className='message-description'>{notification.message}</span>
                            </div>
                            <Cross onClick={()=> setNotification({...notification, isVisible:false})} className='cross-container'/>
                        </div>
                    </div>
                 ) : <React.Fragment/>
             }}
        </MyContext.Consumer>
    );
}

export default MyNotification;
