
export function isEmpty (data) {
    let empty = false;
    if(!data || !data.trim()) empty = true
    return empty;
}


export function validateEmail(email) {
    // eslint-disable-next-line
    const re = /^[a-zA-Z0-9](([^<>()\[\]\\,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
    const repeatRegex = /([@#!#$%&'*+-/=?^_`{|])\1/
    return (re.test(String(email).toLowerCase()) && !repeatRegex.test(String(email).toLowerCase()));
}


export function getErrorMessage(key) {
    if(key === 'pwd') key = "secret"; // to fix snyk issue

    let validations =  {
        "userName": "Error missing username",
        "userNameLen" : "Length should be more than 8 characters",
        "userNameSpecialChars" : "Please use only .-_@ as special characters in user name",
        "secret": "Error missing password",
        "confirmPwd": "Error confirm password missing",
        "confirmPwdNotMatched": "Error passwords do not match",
        "securityQuestion": "Select security question",
        "tcChecked": "Please agree to terms & conditions",
        "answer": "Error missing answer",
        "userNameDuplicate": "Error username already exists",
        "mfaCode": "Error missing code",
        "invalidMfaCode": "Error invalid code"
    }
    return validations[key];
}

const Validations = {
    isEmpty, validateEmail, getErrorMessage
};

export default Validations;